import { createContext, useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'next/navigation';
import { useGetSiteInfo } from './hooks/useGetSiteInfo';
import { DYNAMIC_FEATURE_TOGGLES, SiteInfo } from './types';

const SiteInfoContext = createContext<SiteInfo | undefined>(undefined);

declare global {
  interface Window {
    featureToggles: Readonly<unknown>[];
  }
}

function addPushEventHandler<T>(array: T[], callback: (entry: T[]) => void) {
  const augmentedArray = array;
  augmentedArray.push = function arrayPush(...items) {
    const result = Array.prototype.push.call(array, ...items);
    callback(items);
    return result;
  };
}

function getFeatureToggles(raw: Readonly<unknown>[]) {
  return raw.reduce(
    (toggles, payload) => {
      if (!Array.isArray(payload)) {
        return toggles;
      }

      const [feature, value] = payload;
      if (!DYNAMIC_FEATURE_TOGGLES.includes(feature)) {
        return toggles;
      }

      return {
        ...toggles,
        [feature]: !!value,
      };
    },
    {} as Record<string, boolean>,
  );
}

const makeCookieName = (name: string): string => `featureToggles[${name}]`;

const useFeatureToggles = () => {
  const searchParams = useSearchParams();

  const [featureToggles, setFeatureToggles] = useState<Record<string, boolean>>(
    DYNAMIC_FEATURE_TOGGLES.reduce(
      (toggles, variant) => {
        const cookieName = makeCookieName(variant);
        if (searchParams && searchParams.has(cookieName)) {
          return {
            ...toggles,
            [variant]: searchParams.get(cookieName) === 'true',
          };
        }
        return toggles;
      },
      {} as Record<string, boolean>,
    ),
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.featureToggles = Array.isArray(window.featureToggles)
        ? window.featureToggles
        : [];

      if (window.featureToggles.length) {
        setFeatureToggles({
          ...featureToggles,
          ...getFeatureToggles(window.featureToggles),
        });
      }

      addPushEventHandler(window.featureToggles, newToggles => {
        setFeatureToggles({
          ...featureToggles,
          ...getFeatureToggles(newToggles),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return featureToggles;
};

const SiteInfoProvider = ({ children }: React.PropsWithChildren) => {
  const siteInfo = useGetSiteInfo();
  const dynamicToggles = useFeatureToggles();

  const context = useMemo(
    () => ({
      ...siteInfo,
      featureToggles: {
        ...siteInfo.featureToggles,
        ...dynamicToggles,
      },
    }),
    [siteInfo, dynamicToggles],
  );

  return (
    <SiteInfoContext.Provider value={context}>
      {children}
    </SiteInfoContext.Provider>
  );
};

export { SiteInfoContext, SiteInfoProvider };
