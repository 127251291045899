import styled from '@emotion/styled';
import { lighten } from 'polished';

const StyledList = styled.ul`
  position: absolute;
  left: 0;
  top: 3.5rem;
  z-index: 50;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.color.white};
  border: 0.125rem solid ${({ theme }) => theme.color.grey03};
  border-radius: 0.5rem;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 10%),
    0 2px 4px -2px rgb(0 0 0 / 10%);
  font-size: 1rem;
  line-height: 1.5;
  list-style-type: none;

  @media (max-height: 250px) {
    top: 2.75rem;
    max-height: 55vh;
  }

  @media (max-width: 319px) {
    top: 2.75rem;
    max-height: 60vh;
  }
`;

const ListItem = styled.li`
  &:not(:first-of-type) {
    border-top: 1px solid ${({ theme }) => theme.color.grey03};
  }
`;

const StyledButton = styled.button`
  display: inline-grid;
  grid-template: auto / 1.5rem 1fr;
  gap: 0 0.5rem;
  align-items: center;
  margin: 0;
  padding: 0.75rem;
  border: 0;
  outline: 0;
  width: 100%;
  font: inherit;
  text-align: left;
  color: ${({ theme }) => theme.color.text};
  background: none;
  cursor: pointer;

  @media (max-width: 319px) {
    display: block;
  }

  &:hover,
  &:focus-visible,
  &[aria-selected='true'] {
    color: ${({ theme }) => theme.color.text};
    background-color: ${({ theme }) =>
      lighten(0.285, theme.color.primaryHighlight!)};
  }
`;

const IconWrapper = styled.span`
  grid-row: 1 / span 2;
  display: flex;

  @media (max-width: 319px) {
    display: none;
  }

  svg {
    fill: currentcolor;
  }
`;

const Price = styled.data`
  display: block;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.color.grey02};
`;

const ErrorMessage = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #bf3d1f;
  color: ${({ theme }) => theme.color.white};
  font-size: 0.75rem;
  border: 0;
  border-radius: 0;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  padding: 1rem;
  z-index: 3;
`;

export { ListItem, StyledList, StyledButton, Price, IconWrapper, ErrorMessage };
