import { account, analytics } from '@silvertours/front-entities';
import { useCallback, useMemo, useState } from 'react';
import { LoginContext } from './LoginState';

const LoginProvider = ({ children }: React.PropsWithChildren) => {
  const { accessToken } = account.useAccount();
  const loggedInStatus = Boolean(accessToken);
  const [isLoggedIn, setLoggedIn] = useState(loggedInStatus);
  const [showLogin, setShowLogin] = useState(false);
  const [showMobileLogin, setShowMobileLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [error, setError] = useState<account.LoginError | null>(null);

  if (isLoggedIn !== loggedInStatus) {
    setLoggedIn(loggedInStatus);
  }

  const handleShowLogin = useCallback(
    (show: boolean) => {
      setShowLogin(show);
      if (show) {
        analytics.gtm.trackShowLogin({ isLoggedIn });
      }
    },
    [isLoggedIn],
  );

  const handleShowMobileLogin = useCallback(
    (show: boolean) => {
      setShowMobileLogin(show);
      if (show) {
        analytics.gtm.trackShowLogin({ isLoggedIn });
      }
    },
    [isLoggedIn],
  );

  const value = useMemo(
    () => ({
      isLoggedIn,
      setLoggedIn,
      error,
      setError,
      showLogin,
      setShowLogin: handleShowLogin,
      showMobileLogin,
      setShowMobileLogin: handleShowMobileLogin,
      username,
      setUsername,
    }),
    [
      error,
      handleShowLogin,
      handleShowMobileLogin,
      isLoggedIn,
      showLogin,
      showMobileLogin,
      username,
    ],
  );

  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
};

export { LoginProvider };
