import React, { PropsWithChildren, useState } from 'react';
import Menu from '@engineering/icons/menu';
import Comment from '@engineering/icons/comment';
import User from '@engineering/icons/user';
import World from '@engineering/icons/world';
import {
  BilligerMietwagenSiteViewContent,
  ContentLayout,
  HasInfoBanner,
  PageOfSiteProps,
  StaticLink,
} from '@silvertours/common-landingpages-view';
import {
  MembershipPopover,
  OpeningTimes,
  useSiteInfo,
} from '@silvertours/front-entities';
import {
  PageLegacy,
  InfoBanner,
  Header,
  LoginModal,
  LoginForm,
  MarketPickerModal,
  LocalizedMarketPicker,
  useLogin,
  Footer,
} from '@silvertours/front-features';
import { useTranslations } from 'next-intl';
import { useStaticLinks } from './useStaticLinks';
import { MarketPickerWrapper, StyledHeader } from './Content.style';

type Page = HasInfoBanner;

const ContactMenu = () => <OpeningTimes showVoucherInfo />;

const LoginMobileMenu = () => {
  const { isLoggedIn, showMobileLogin, setShowMobileLogin } = useLogin();

  const handleLoginClick: React.MouseEventHandler<Element> = event => {
    event.preventDefault();
    setShowMobileLogin(true);
  };
  const t = useTranslations('features.navigationLegacy.navigation');

  const handleSignUpClick = () => {
    window.location.assign(t('newUser.href'));
  };

  if (isLoggedIn) {
    return <Header.LoggedInActions />;
  }

  return showMobileLogin ? (
    <LoginForm />
  ) : (
    <MembershipPopover
      handleLoginClick={handleLoginClick}
      handleSignUpClick={handleSignUpClick}
    />
  );
};

const MobileMarketPickerMenu = () => (
  <LocalizedMarketPicker onSubmit={() => {}} />
);

const makeHamburgerMenu =
  (links: StaticLink[]) => (handleCloseMenu: () => void) => (
    <Header.Navigation links={links} mode="mobile" onAction={handleCloseMenu} />
  );

const ContentDisplay = ({
  children,
  page,
}: PageOfSiteProps<BilligerMietwagenSiteViewContent, ContentLayout<Page>, {}> &
  PropsWithChildren) => {
  const {
    getHeaderLinks,
    getFooterLinks,
    getLegalLinks,
    getSocialMediaLinks,
    getAppLinks,
  } = useStaticLinks();
  const headerLinks = getHeaderLinks(page.navigation.staticLinks);
  const footerLinks = getFooterLinks(page.navigation.staticLinks);
  const legalLinks = getLegalLinks(page.navigation.staticLinks);
  const socialMediaLinks = getSocialMediaLinks(page.navigation.staticLinks);
  const appLinks = getAppLinks(page.navigation.staticLinks);
  const [showMarketPickerModal, setShowMarketPickerModal] = useState(false);
  const t = useTranslations('features.navigationLegacy.navigation');
  const openingTimesTooltip = useTranslations(
    'features.supportInfoLegacy.openingTimes.tooltip',
  )('title');

  const { setShowMobileLogin } = useLogin();

  const {
    featureToggles: { hideInfoline, showEnglishInMarketPicker },
  } = useSiteInfo();

  return (
    <PageLegacy.PageBase>
      <StyledHeader
        desktopActions={[
          {
            key: 'market_picker',
            component: (
              <>
                <Header.Separator />
                <MarketPickerWrapper>
                  <MarketPickerModal
                    show={showMarketPickerModal}
                    setShow={setShowMarketPickerModal}
                  />
                </MarketPickerWrapper>
              </>
            ),
            hide: !showEnglishInMarketPicker,
          },
          {
            key: 'infoline',
            component: (
              <Header.InfolinePopoverButton popupContent={<ContactMenu />} />
            ),
            hide: hideInfoline,
          },
          {
            key: 'login',
            component: <Header.LoginButton />,
          },
        ]}
        desktopLinks={headerLinks}
        mobileMenus={[
          {
            id: 'mobile-menu-locale',
            closeLabel: t('header.closeLabel'),
            iconLabel: openingTimesTooltip,
            content: MobileMarketPickerMenu,
            icon: World,
            hide: !showEnglishInMarketPicker,
          },
          {
            id: 'mobile-menu-contact',
            closeLabel: t('header.closeLabel'),
            iconLabel: openingTimesTooltip,
            content: ContactMenu,
            icon: Comment,
            hide: hideInfoline,
          },
          {
            id: 'mobile-menu-account',
            closeLabel: t('header.closeLabel'),
            iconLabel: t('login.button.account'),
            content: LoginMobileMenu,
            icon: User,
            onClose: () => setShowMobileLogin(false),
          },
          {
            id: 'mobile-menu-hamburger',
            closeLabel: t('header.closeLabel'),
            iconLabel: t('header.menuLabel'),
            content: makeHamburgerMenu(headerLinks),
            icon: Menu,
          },
        ]}
      >
        {page.content?.infoBanner && (
          <InfoBanner {...page.content.infoBanner} />
        )}
      </StyledHeader>
      <LoginModal />
      {children}
      <Footer
        footerLinks={footerLinks}
        legalLinks={legalLinks}
        socialMediaLinks={socialMediaLinks}
        appLinks={appLinks}
      />
    </PageLegacy.PageBase>
  );
};

export { ContentDisplay };
