import { LandingPage } from '../../entity';
import { PageClassification } from './pageClassification';
import { Pillar } from './pageClassificationRule';

interface PageClassificationStrategy {
  classify(page: LandingPage): PageClassification;

  strategyApplies(page: LandingPage): boolean;
}

abstract class PageClassificationStrategyBase {
  constructor(
    private pageType: string,
    private pillars?: Pillar[],
    protected includeBreadcrumbs?: false,
    protected omitStageImage?: boolean,
  ) {}

  protected withCommonValues(
    classification: Partial<PageClassification>,
  ): PageClassification {
    const pillarLinks = (this.pillars || []).map(
      ({ extension = 'html', name, slug }) => ({
        name,
        url: `/${slug}.${extension}`,
      }),
    );

    return {
      pageType: this.pageType,
      includeBreadcrumbs: this.includeBreadcrumbs,
      omitStageImage: this.omitStageImage,
      pillarLinks,
      ...classification,
    };
  }
}

export { PageClassificationStrategyBase };
export type { PageClassificationStrategy };
