import { http } from '@silvertours/front-shared';
import { useEffect } from 'react';
import { getDefaults } from '../defaults';
import { useConfig, useLocale } from '../../Runtime';
import { FeatureToggles, InfolineTimes, SiteSettings } from '../types';

const useGetSiteInfo = () => {
  const { api } = useConfig();
  const { locale } = useLocale();
  const defaults = getDefaults(locale);

  const { data: settingsData, request: settingsRequest } =
    http.useClientSideRequest<SiteSettings | null>(
      api.host,
      http.API.siteSettings,
    );

  const { data: featuresData, request: featuresRequest } =
    http.useClientSideRequest<FeatureToggles | null>(
      api.host,
      http.API.siteFeatures,
    );

  const { data: openingTimesData, request: openingTimesRequest } =
    http.useClientSideRequest<InfolineTimes | null>(
      api.host,
      http.API.openingHours,
    );

  useEffect(() => {
    settingsRequest('get');
    featuresRequest('get');
    openingTimesRequest('get');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    settings: settingsData || defaults.settings,
    featureToggles: featuresData || defaults.featureToggles,
    openingTimes: openingTimesData || defaults.openingTimes,
    settingsLoaded: !!settingsData,
  };
};

export { useGetSiteInfo };
