import React from 'react';

const NewsletterSuccessIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="40" cy="40" r="40" fill="#D4EDE9" />
    <rect width="48" height="48" transform="translate(16 16)" fill="#D4EDE9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 26.5C22.5147 26.5 20.5 28.5147 20.5 31V51C20.5 53.4853 22.5147 55.5 25 55.5H55C57.4853 55.5 59.5 53.4853 59.5 51V31C59.5 28.5147 57.4853 26.5 55 26.5H25ZM24.7283 29.5245C24.8164 29.5084 24.9072 29.5 25 29.5H55C55.0928 29.5 55.1836 29.5084 55.2717 29.5245L40.3308 42.7077C40.1418 42.8745 39.8582 42.8745 39.6692 42.7077L24.7283 29.5245ZM23.5 32.4416V51C23.5 51.1007 23.5099 51.199 23.5288 51.2942L33.0699 40.8857L23.5 32.4416ZM54.5068 52.5H26.4932L35.3199 42.8709L37.6843 44.9572C39.0074 46.1246 40.9926 46.1246 42.3157 44.9572L45.233 42.3831L54.5068 52.5ZM56.5 50.2347V32.4416L47.4829 40.3979L56.5 50.2347Z"
      fill="#00977D"
    />
  </svg>
);

export { NewsletterSuccessIcon };
