import React, { createContext, useContext } from 'react';

export type LoginProps = {
  accessToken: string | null;
  clearAccountState: () => void;
  refreshToken: string | null;
  setAccessToken: React.Dispatch<React.SetStateAction<string | null>>;
  setRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;
};

const AccountContext = createContext<LoginProps | null>(null);

const useAccount = () => {
  const context = useContext(AccountContext);

  if (context === null) {
    throw new Error(
      'Ensure you have wrapped the useAccount call in a AccountProvider',
    );
  }

  return context;
};

export { AccountContext, useAccount };
