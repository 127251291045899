import styled from '@emotion/styled';
import { transparentize } from 'polished';
import { Ui } from '@silvertours/front-shared';
import StagePromoImage from './StagePromo.jpg';

export const Wrapper = styled.aside`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  font-family: ${({ theme }) => theme.fontFamily.alternate};
  transform: translateY(-50%);
`;

export const StyledBadge = styled(Ui.Badge)`
  position: absolute;
  top: -1.25rem;
  left: 1.25rem;
  z-index: 1;
`;

export const Content = styled.button`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin: 0;
  padding: 1em 7em 0 1.25rem;
  border: 0;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  outline: 0;
  width: 443px;
  height: 150px;
  box-sizing: border-box;
  font: inherit;
  font-size: 1.375rem;
  font-weight: normal;
  line-height: 1.25;
  text-align: left;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.brand} url(${StagePromoImage.src})
    no-repeat;
  background-size: cover;
  filter: drop-shadow(
    0 0.25rem 0.25rem
      ${({ theme }) => transparentize(0.75, theme.color.black ?? 'black')}
  );
  appearance: none;
  cursor: pointer;
`;

export const Big = styled.span`
  font-size: 2.25rem;
  text-shadow: 0 0.25rem 0.25rem
    ${({ theme }) => transparentize(0.875, theme.color.black ?? 'black')};
`;

export const Uppercase = styled.span`
  text-transform: uppercase;
`;
