import { PageAttributeType } from '@silvertours/back-domain-page';
import { String } from '@silvertours/front-shared';
import { format } from 'date-fns/format';
import { useRouter } from 'next/router';
import { SearchLocation } from '@silvertours/common-landingpages-view';
import { Runtime, SearchDestinationType } from '@silvertours/front-entities';
import { SearchFormState } from './types';
import { getDefaultValuesPerLanguage } from './reducer';

const getQueryParamValues = (query: any) => {
  const values: Partial<SearchFormState> = {};

  values.showDestLocation = !!query.dest;
  values.affiliate = query.affiliate || query.aff || '';

  if (query.dep || query.depAirport) {
    // e.g. ?dep=Berlin, or ?depAirport=BER
    const name = (query.dep as string) || '';
    const iataCode = (query.depAirport as string) || '';
    values.depLocation = {
      name,
      cityCode: iataCode,
      type: iataCode ? 'airport' : 'all',
      iataCode,
    };
  }

  if (query.dest || query.destAirport) {
    // e.g. ?dest=Madrid, or ?depAirport=MAD
    const name = (query.dest as string) || '';
    const iataCode = (query.destAirport as string) || '';
    values.destLocation = {
      name,
      cityCode: iataCode,
      type: iataCode ? 'airport' : 'all',
      iataCode,
    };
  }

  if (typeof query.depDate === 'string') {
    // e.g. ?depDate=2022-12-01
    values.depDate = format(
      new Date(query.depDate),
      String.isoDateTimeWithSeconds,
    );
  }
  if (typeof query.destDate === 'string') {
    // e.g. ?destDate=2022-12-07
    values.destDate = format(
      new Date(query.destDate),
      String.isoDateTimeWithSeconds,
    );
  }
  if (typeof query.customerAge === 'string') {
    values.customerAge = query.customerAge;
  }

  if (typeof query.provider === 'string') {
    values.provider = query.provider;
  }

  return values;
};

const ATTRIBUTE_TO_SEARCH_TYPE: { [K: string]: SearchDestinationType } = {
  airport: 'airport',
  city: 'all',
  cityDistrict: 'city',
  railStation: 'rail',
};

const mapStageLocationToQuery = (
  location: SearchLocation | null,
): { inputValue: string; query: SearchFormState['depLocation'] } => {
  if (
    !location ||
    (location.type !== PageAttributeType.Airport &&
      location.type !== PageAttributeType.City)
  ) {
    return { inputValue: '', query: null };
  }

  const type = ATTRIBUTE_TO_SEARCH_TYPE[location.type] || 'all';
  return {
    inputValue: location.displayName,
    query: {
      name: location.query || '',
      cityName: location.cityName || '',
      type,
      ...(location.cityCode ? { cityCode: location.cityCode } : {}),
    },
  };
};

export const useSearchInitializer = () => {
  const router = useRouter();
  const { language } = Runtime.useLocale();

  return {
    /** "Plain" search state to use when needed outside of search form */
    getDefaultState: (location: SearchLocation | null) => {
      const stageLocationQuery = mapStageLocationToQuery(location);

      return {
        ...getDefaultValuesPerLanguage(language),
        ...(stageLocationQuery.query
          ? { depLocation: stageLocationQuery.query }
          : {}),
      };
    },

    /**
     * Search form initial state has multiple sources of truth, in order of importance:
     * 1. Stored in query params
     * 2. City name retrieved from page content (within stage property)
     * 3. Stored in session storage
     * 4. Hard-coded default values
     *
     * Note: session storage must be rendered separately because SSR doesn't have access to browser data
     */
    initializeFormState: (
      defaultValues: SearchFormState,
      location: SearchLocation | null,
    ): SearchFormState => {
      const queryParams = getQueryParamValues(router.query);
      const stageLocationQuery = mapStageLocationToQuery(location);

      const initialState = {
        ...defaultValues,
        ...(stageLocationQuery.query
          ? { depLocation: stageLocationQuery.query }
          : {}),
        ...queryParams,
      } as SearchFormState;

      return {
        ...initialState,
        inputValue: {
          departure:
            queryParams.depLocation?.name ||
            stageLocationQuery.inputValue ||
            initialState.depLocation?.name ||
            '',
          destination: initialState.destLocation?.name || '',
        },
        showDestLocation: !!initialState.destLocation,
      };
    },
  };
};
