import { useMedia } from 'react-use';
import { analytics, Runtime, useSiteInfo } from '@silvertours/front-entities';
import {
  Big,
  Content,
  StyledBadge,
  Uppercase,
  Wrapper,
} from './StagePromo.styles';

const StagePromo = () => {
  const {
    featureToggles: { promotionOnStageImage },
  } = useSiteInfo();
  const { language } = Runtime.useLocale();
  const hasEnoughSpace = useMedia('(min-width: 1056px)', true);

  if (!promotionOnStageImage || !hasEnoughSpace || language !== 'de') {
    return null;
  }

  const handleClick = () => {
    window.open(
      'https://billiger-mietwagen.campaign.playable.com/quizmas-time',
    );
    analytics.gtm.trackPromoClicked('advent_raffle_2024');
  };

  return (
    <Wrapper>
      <StyledBadge type="orange" text="03. – 25.12.2024" />
      <Content type="button" onClick={handleClick}>
        <Big>Quizmas-Time</Big>
        <Uppercase>Ho Ho Hol’ dir jetzt deine Gewinne!</Uppercase>
      </Content>
    </Wrapper>
  );
};

export { StagePromo };
