import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';

const DropdownWrapper = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: 1rem;
`;

const Dropdown = styled(Ui.Dropdown)`
  min-width: 8em;
`;

export { DropdownWrapper, Dropdown };
