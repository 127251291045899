import { useState } from 'react';
import type { ChangeEvent } from 'react';
import { useTranslations } from 'next-intl';
import concat from 'lodash/concat';
import { UiLegacy } from '@silvertours/front-legacy-shared';
import { DropdownWrapper, Dropdown } from './ProviderDropdown.styles';

type ProviderDropdownProps = {
  value?: string;
  label: string;
  onChange: (provider: string) => void;
};

const DEFAULT_PROVIDER = '';
const PROVIDERS = [
  { label: 'Alamo', value: 'alamo' },
  { label: 'Autoeurope', value: 'autoeurope' },
  { label: 'Avis', value: 'avis' },
  { label: 'BspAuto', value: 'bspauto' },
  { label: 'Budget', value: 'budget' },
  { label: 'Carnect', value: 'carnect' },
  { label: 'Cars and Camper', value: 'carsandcamper' },
  { label: 'Cartrawler (Argus)', value: 'cartrawler' },
  { label: 'DerTour', value: 'dertour' },
  { label: 'DriveFTI', value: 'drivefti' },
  { label: 'Enterprise', value: 'enterprise' },
  { label: 'Ergo', value: 'erv_insurance' },
  { label: 'Europcar', value: 'europcar' },
  { label: 'Hertz', value: 'hertz' },
  { label: 'Rentalcars', value: 'rentalcars' },
  { label: 'Sunnycars', value: 'sunnycars' },
  { label: 'Wisecars', value: 'wisecars' },
];

const ProviderDropdown = ({
  value,
  label,
  onChange,
}: ProviderDropdownProps) => {
  const t = useTranslations('features.stageLegacy.stage.searchForm');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const providerDropdownData = concat(
    {
      label: 'provider',
      value: DEFAULT_PROVIDER,
    },
    PROVIDERS.map(provider => ({
      label: provider.label,
      value: provider.value,
    })),
  );

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const provider = event.target.value;
    onChange?.(provider);
  };

  return (
    <DropdownWrapper>
      {showDropdown ? (
        <Dropdown
          id="provider"
          label={t('provider.select.label')}
          value={value}
          options={providerDropdownData}
          onChange={handleChange}
        />
      ) : (
        <UiLegacy.Checkbox
          id="allProvider"
          label={label}
          checked
          onChange={() => setShowDropdown(!showDropdown)}
        />
      )}
    </DropdownWrapper>
  );
};

export { ProviderDropdown };
