import { SupplierReview } from '@silvertours/common-landingpages-view';
import { DataInterop } from '@silvertours/front-shared';

type MapAutoRentalStructuredDataProps = {
  supplierReviews: SupplierReview[];
};

const SupplierReviewsData = ({
  supplierReviews,
}: MapAutoRentalStructuredDataProps) => {
  if (!supplierReviews.length) {
    return null;
  }

  return (
    <DataInterop.StructuredData
      type="Product" // this should be `AutoRental` but we have no `address` data which is required then
      data={supplierReviews.map(review => ({
        name: review.name,
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: review.totalRating.value,
          bestRating: review.totalRating.max,
          worstRating: review.totalRating.min,
          reviewCount: review.reviewCount,
        },
      }))}
      includeContext
    />
  );
};

export { SupplierReviewsData };
