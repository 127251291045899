import { useEffect, useMemo, useState } from 'react';
import { http } from '@silvertours/front-shared';
import { AccountContext } from './Context';
import { useAccountConfig } from '../../Runtime';

type AccountSessionResponse = {
  access_token: string | null;
  refresh_token: string | null;
};

const AccountProvider = ({ children }: React.PropsWithChildren) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const { formatSessionUrl } = useAccountConfig();

  const getAccountSession = async () => {
    const res = await http.axiosInstance.get<AccountSessionResponse>(
      formatSessionUrl(http.API.accountSession),
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    setAccessToken(res.data.access_token);
    setRefreshToken(res.data.refresh_token);

    return res.data;
  };

  useEffect(() => {
    getAccountSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = useMemo(
    () => ({
      accessToken,
      refreshToken,
      setAccessToken,
      setRefreshToken,
      clearAccountState: () => {
        setAccessToken(null);
        setRefreshToken(null);
      },
    }),
    [accessToken, refreshToken],
  );

  return (
    <AccountContext.Provider value={context}>
      {children}
    </AccountContext.Provider>
  );
};

export { AccountProvider };
