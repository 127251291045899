import { FormEvent, useRef, useState } from 'react';
import { useTranslations } from 'next-intl';
import { Button, Ui } from '@silvertours/front-shared';

import {
  Form,
  LegalText,
  ToggleLink,
  StyledArrowDown,
  StyledArrowUp,
} from './NewsletterRegistration.styles';

type NewsletterSignupProps = {
  isIndexType: boolean;
  onSubmit: (email: string) => void;
};

const NewsletterSignup = ({ isIndexType, onSubmit }: NewsletterSignupProps) => {
  const t = useTranslations(
    'features.newsletterRegistrationLegacy.newsletterRegistration',
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (inputRef.current) {
      onSubmit(inputRef.current.value);
    }
  };

  const [showAll, setAllVisible] = useState(false);
  const legalText = t.raw('legalText');
  const index = legalText.indexOf(' ', 300);
  const showAllText = showAll || !isIndexType;

  return (
    <Form method="post" id="newsletter-form" onSubmit={handleSubmit}>
      <Ui.Input
        ref={inputRef}
        type="email"
        id="newsletter-email"
        name="email"
        label={t('label')}
        autoComplete="email"
        autoCorrect="off"
        autoCapitalize="none"
        required
      />
      {showAllText ? (
        <>
          <LegalText
            showAllText
            isIndexType={isIndexType}
            dangerouslySetInnerHTML={{ __html: t.raw('legalText') }}
          />
          {isIndexType && (
            <ToggleLink variant="text" onClick={() => setAllVisible(false)}>
              {t('showLess')} <StyledArrowUp />
            </ToggleLink>
          )}
        </>
      ) : (
        <>
          <LegalText
            showAllText={false}
            isIndexType={isIndexType}
            dangerouslySetInnerHTML={{
              __html: index === -1 ? legalText : legalText.substring(0, index),
            }}
          />
          <ToggleLink variant="text" onClick={() => setAllVisible(true)}>
            {t('showMore')} <StyledArrowDown />
          </ToggleLink>
        </>
      )}
      <Button
        type="submit"
        variant={isIndexType ? 'secondary' : 'primary'}
        fullWidth
      >
        {t('buttonText')}
      </Button>
    </Form>
  );
};

export { NewsletterSignup };
