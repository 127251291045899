import React, { FormEvent, useState } from 'react';
import Image from 'next/image';

import { Ui } from '@silvertours/front-shared';
import { account, analytics } from '@silvertours/front-entities';

import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import {
  StyledForm,
  TitleWrapper,
  Title,
  InputWrapper,
  PasswordLostWrapper,
  Footer as StyledFooter,
  SwirlWrapper,
  SubmitButton,
  FormActionArea,
  MaxWidthWrapper,
} from './LoginForm.styles';
import Swirl from './swirl.png';
import { LoginError } from './LoginError';
import { useLogin } from './LoginState';
import { Footer } from './Footer';

type Props = {
  withinModal?: boolean;
};

const LoginForm = ({ withinModal }: Props) => {
  const [loading, setLoading] = useState(false);
  const { setUsername, setShowLogin, setLoggedIn, setError } = useLogin();

  const isLoggedIn = false;

  const { login } = account.useAccountRequest();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const emailAddress = (
      event.currentTarget.elements.namedItem('emailAddress') as HTMLInputElement
    ).value;
    const password = (
      event.currentTarget.elements.namedItem('password') as HTMLInputElement
    ).value;

    setUsername(emailAddress);
    setLoading(true);
    const { loggedIn, error: errorMessage } = await login(
      emailAddress,
      password,
    );

    if (loggedIn) {
      setShowLogin(false);
      setLoggedIn(true);
    } else {
      setError(errorMessage || null);
      setLoggedIn(false);
    }
    setLoading(false);
  };

  const t = useTranslations('features.navigationLegacy.navigation');
  const router = useRouter();
  const actionPath = t('login.href', {
    redirectPath: encodeURIComponent(router.asPath),
  });

  return (
    <>
      <StyledForm method="post" action={actionPath} onSubmit={handleSubmit}>
        <SwirlWrapper>
          <Image src={Swirl} alt="" fill />
        </SwirlWrapper>

        <TitleWrapper>
          <Title>{t('login.title')}</Title>
        </TitleWrapper>

        <LoginError />

        <FormActionArea>
          <MaxWidthWrapper>
            <InputWrapper>
              <Ui.Input
                id="email"
                type="email"
                name="emailAddress"
                label={t('login.emailLabel')}
                autoComplete="section-login username"
                autoCorrect="off"
                autoCapitalize="none"
                required
              />
            </InputWrapper>
            <InputWrapper>
              <Ui.Input
                id="password"
                type="password"
                name="password"
                label={t('login.passwordLabel')}
                autoComplete="section-login current-password"
                required
              />
            </InputWrapper>

            <PasswordLostWrapper>
              <Ui.TextLink
                href={t('passwordLost.href')}
                text={t('passwordLost.text')}
                onClick={() =>
                  analytics.gtm.trackPasswordForgotten({ isLoggedIn })
                }
              />
            </PasswordLostWrapper>

            <SubmitButton
              type="submit"
              loading={loading}
              disabled={loading}
              onClick={analytics.gtm.trackLogin}
              fullWidth
            >
              {t('login.submitText')}
            </SubmitButton>
          </MaxWidthWrapper>
        </FormActionArea>
      </StyledForm>
      {!withinModal && (
        <StyledFooter>
          <Footer isLoggedIn={isLoggedIn} />
        </StyledFooter>
      )}
    </>
  );
};

export { LoginForm };
