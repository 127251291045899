import React, { useCallback, SelectHTMLAttributes, ChangeEvent } from 'react';
import { datetime, String, TimePicker } from '@silvertours/front-shared';
import { analytics, Runtime, useSiteInfo } from '@silvertours/front-entities';
import { format } from 'date-fns/format';
import { useTranslations } from 'next-intl';
import ArrowBack from '@engineering/icons/arrow-back';
import ArrowForward from '@engineering/icons/arrow-forward';
import Calendar from '@engineering/icons/calendar';
import { UiLegacy } from '@silvertours/front-legacy-shared';
import concat from 'lodash/concat';
import range from 'lodash/range';
import { DEFAULT_AGE, useSearchContext } from '../SearchFormLegacy/context';
import { SceneProps, WizardOverlay } from './common';
import { getTime, setTime } from './datetime';
import { CalendarScene } from './CalendarScene';
import { SelectStationScene } from './SelectStationScene';
import { ErrorMessages } from './ErrorMessages';
import { ProviderDropdown } from './ProviderDropdown';
import {
  DropdownWrapper,
  IconWrapper,
  InfoIcon,
  InputButton,
  InputButtonLabel,
  InputWrapper,
  StyledDropdown,
} from './SearchWizard.styles';

type SubSceneKey = 'pickupLocation' | 'returnLocation' | 'calendar';

const AgeDropdown = (props: SelectHTMLAttributes<HTMLSelectElement>) => {
  const t = useTranslations('features.stage.search.summary');

  const ageDropdownData = concat(
    range(18, 26).map(item => ({
      label: item.toString(),
      value: item.toString(),
    })),
    {
      label: t('age.defaultAge'),
      value: DEFAULT_AGE.toString(),
    },
    range(66, 100).map(item => ({
      label: item.toString(),
      value: item.toString(),
    })),
  );

  return (
    <StyledDropdown
      label={t('age.label')}
      options={ageDropdownData}
      {...props}
    />
  );
};

const SearchFormScene = (props: SceneProps) => {
  const { onConfirm } = props;
  const siteInfo = useSiteInfo();
  const { state, setCustomerAge, setDate, setProvider } = useSearchContext();
  if (!state) {
    throw new Error('No search state found');
  }

  const locale = Runtime.useLocale();
  const { dayDate } = String.dateFormats[locale.language];
  const dateLocale = datetime.dateLocales[locale.language];

  const [activeScene, setActiveScene] = React.useState<
    SubSceneKey | undefined
  >();
  const unsetActiveScene = useCallback(
    () => setActiveScene(undefined),
    [setActiveScene],
  );
  const t = useTranslations('features.stage.search');

  const handleConfirm = () => {
    analytics.gtm.trackConfirmSearchClicked();
    onConfirm();
  };

  if (activeScene === 'calendar') {
    return (
      <CalendarScene onClose={unsetActiveScene} onConfirm={unsetActiveScene} />
    );
  }

  if (activeScene === 'pickupLocation') {
    return (
      <SelectStationScene
        onClose={unsetActiveScene}
        onConfirm={unsetActiveScene}
        direction="departure"
      />
    );
  }

  if (activeScene === 'returnLocation') {
    return (
      <SelectStationScene
        onClose={unsetActiveScene}
        onConfirm={unsetActiveScene}
        direction="destination"
      />
    );
  }

  const depDate = new Date(state.depDate);
  const destDate = new Date(state.destDate);
  const invalidTimes = destDate <= depDate;

  return (
    <WizardOverlay
      {...props}
      onConfirm={handleConfirm}
      title={t('headline')}
      buttonText={t('summary.cta')}
    >
      <ErrorMessages />
      <InputWrapper>
        <InputButtonLabel>{t('summary.station.pickUp.label')}</InputButtonLabel>
        <InputButton
          type="button"
          aria-haspopup="true"
          onClick={() => {
            analytics.gtm.trackEditDepartureClicked();
            setActiveScene('pickupLocation');
          }}
        >
          <IconWrapper>
            <ArrowForward />
          </IconWrapper>
          {state.depLocation?.name ?? ''}
        </InputButton>
      </InputWrapper>
      <InputWrapper>
        <InputButtonLabel>{t('summary.station.return.label')}</InputButtonLabel>
        <InputButton
          type="button"
          aria-haspopup="true"
          onClick={() => {
            analytics.gtm.trackEditDestinationClicked();
            setActiveScene('returnLocation');
          }}
        >
          <IconWrapper>
            <ArrowBack />
          </IconWrapper>
          {state.destLocation?.name ?? state.depLocation?.name ?? ''}
        </InputButton>
      </InputWrapper>
      <InputWrapper>
        <InputButtonLabel>{t('summary.travelPeriod.label')}</InputButtonLabel>
        <InputButton
          type="button"
          aria-haspopup="true"
          onClick={() => {
            analytics.gtm.trackEditDatesClicked();
            setActiveScene('calendar');
          }}
        >
          <IconWrapper>
            <Calendar />
          </IconWrapper>
          {format(depDate, dayDate, { locale: dateLocale })}
          {' - '}
          {format(destDate, dayDate, { locale: dateLocale })}
        </InputButton>
      </InputWrapper>
      <InputWrapper>
        <TimePicker
          from={getTime(depDate)}
          to={getTime(destDate)}
          translations={{
            title: t('calendar.timePicker.title'),
            from: t('calendar.timePicker.from'),
            to: t('calendar.timePicker.to'),
            hours: t('calendar.timePicker.hours'),
            minutes: t('calendar.timePicker.minutes'),
            closeButton: t('calendar.timePicker.closeButton'),
            confirmButton: t('calendar.timePicker.confirmButton'),
            errorMessage: invalidTimes ? t('errors.time') : undefined,
          }}
          onChange={times => {
            analytics.gtm.trackSelectTime();
            setDate('departure', setTime(depDate, times.from));
            setDate('destination', setTime(destDate, times.to));
          }}
        />
      </InputWrapper>
      <DropdownWrapper>
        <AgeDropdown
          value={state.customerAge}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            setCustomerAge(Number(event.target.value));
            analytics.gtm.trackConfirmAge();
          }}
        />
        <UiLegacy.Tooltip
          content={<UiLegacy.Markup content={t('summary.age.tooltip.text')} />}
          trigger={<InfoIcon />}
        />
      </DropdownWrapper>
      {siteInfo.settings.isInternalCall && state && (
        <ProviderDropdown
          value={state.provider}
          label={t('summary.provider.label')}
          onChange={setProvider}
        />
      )}
    </WizardOverlay>
  );
};

export { SearchFormScene };
