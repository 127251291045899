import type { ChangeEvent, SetStateAction } from 'react';
import { useTranslations } from 'next-intl';
import { UiLegacy } from '@silvertours/front-legacy-shared';
import { analytics, useSiteInfo } from '@silvertours/front-entities';
import { Theme, Ui } from '@silvertours/front-shared';
import { useMedia } from 'react-use';
import {
  CheckboxContainer,
  OptionsSection,
  OptionsWrapper,
  StyledLabel,
  StyledButton,
} from './SearchFormOptions.styles';
import { CAR_CATEGORY_OPTIONS, EXTRA_OPTIONS } from './const';
import { ProviderDropdown } from '../ProviderDropdown';
import { useSearchContext } from '../context';

type SearchFormOptionsProps = {
  checked: Array<string>;
  onChange: (value: string) => void;
  setShowOptions: React.Dispatch<SetStateAction<boolean>>;
};

const OptionsContainer = ({
  buttonText,
  children,
  isMobile,
  onClose,
  title,
}: React.PropsWithChildren<{
  buttonText: string;
  isMobile: boolean;
  onClose: () => void;
  title: string;
}>) => (
  <OptionsWrapper isMobile={isMobile}>
    {isMobile ? (
      <Ui.Overlay open title={title} onClose={onClose}>
        {children}
        <StyledButton fullWidth onClick={onClose}>
          {buttonText}
        </StyledButton>
      </Ui.Overlay>
    ) : (
      <aside>{children}</aside>
    )}
  </OptionsWrapper>
);

const SearchFormOptions = ({
  checked,
  onChange,
  setShowOptions,
}: SearchFormOptionsProps) => {
  const siteInfo = useSiteInfo();
  const { setProvider, state } = useSearchContext();
  const t = useTranslations('features.stageLegacy.stage.searchForm');
  const isMobile = useMedia(
    `(max-width: ${Theme.getBreakpoint('lg')}px)`,
    false,
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    analytics.gtm.trackOptionsChange(event.target.id);
    onChange?.(event.target.id);
  };

  return (
    <OptionsContainer
      buttonText={t('options.modalButton')}
      onClose={() => {
        setShowOptions(false);
      }}
      isMobile={isMobile}
      title={t('options.modalTitle')}
    >
      <OptionsSection>
        <StyledLabel>{t('options.headline.insurance')}</StyledLabel>
        <UiLegacy.Checkbox
          label={t('options.checkbox.insurance')}
          id="insfull"
          name="insurance_coverage"
          value="full"
          onChange={handleChange}
        />
      </OptionsSection>

      <OptionsSection>
        <StyledLabel>{t('options.headline.vehicleClass')}</StyledLabel>
        <CheckboxContainer>
          {CAR_CATEGORY_OPTIONS.map(({ id, key, value }) => {
            const label = t(`options.checkbox.${key}`);

            if (!label) {
              /* Translation file contains the logic for which options to render per locale */
              return null;
            }

            return (
              <UiLegacy.Checkbox
                checked={checked.includes(value)}
                label={label}
                id={id}
                name="acrissDescriptions[]"
                value={value}
                onChange={handleChange}
                key={id}
              />
            );
          })}
        </CheckboxContainer>
      </OptionsSection>

      <OptionsSection>
        <StyledLabel>{t('options.headline.inclusive')}</StyledLabel>
        <CheckboxContainer>
          {EXTRA_OPTIONS.map(({ id, key, name, value }) => {
            const label = t(`options.checkbox.${key}`);

            if (!label) {
              return null;
            }

            return (
              <UiLegacy.Checkbox
                checked={checked.includes(value)}
                label={label}
                id={id}
                name={name}
                value={value}
                onChange={handleChange}
                key={id}
              />
            );
          })}
        </CheckboxContainer>
      </OptionsSection>
      {siteInfo.settings.isInternalCall && state && (
        <ProviderDropdown
          value={state.provider}
          label={t('provider.label')}
          onChange={setProvider}
        />
      )}
    </OptionsContainer>
  );
};

export { SearchFormOptions };
