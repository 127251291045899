import { useTranslations } from 'next-intl';

import {
  SuccessContainer,
  SuccessText,
  SuccessHeadline,
  SuccessIcon,
} from './NewsletterRegistration.styles';

type NewsletterSignupSuccessProps = {
  showTitle?: boolean;
};

const NewsletterSignupSuccess = ({
  showTitle,
}: NewsletterSignupSuccessProps) => {
  const t = useTranslations(
    'features.newsletterRegistrationLegacy.newsletterRegistration',
  );

  return (
    <SuccessContainer>
      <SuccessIcon />
      {showTitle && <SuccessHeadline>{t('success.title')}</SuccessHeadline>}
      <SuccessText>{t('success.text')}</SuccessText>
      <p>{t('success.subtext')}</p>
    </SuccessContainer>
  );
};

export { NewsletterSignupSuccess };
