import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Ui } from '@silvertours/front-shared';
import Checked from '@engineering/icons/checked';
import ArrowDown from '@engineering/icons/arrow-down';
import ArrowUp from '@engineering/icons/arrow-up';
import { NewsletterSuccessIcon } from './NewsletterSuccessIcon';

const StyledBadge = styled(Ui.Badge)`
  /* stylelint-disable-next-line no-empty-source */
`;

const Card = styled(Ui.Card)`
  padding: ${({ theme }) => theme.spacing[60]};
  position: relative;
`;

const CardWrapper = styled.div`
  margin-top: 1rem;
  ${({ theme }) => theme.mq('md')} {
    margin-top: 5rem;
  }
  ${({ theme }) => theme.mq('lg')} {
    margin-top: -5rem;
  }
  ${({ theme }) => theme.mq('xl')} {
    margin-top: -6rem;
  }
`;

const Container = styled.div`
  ${({ theme }) => theme.mq('lg')} {
    display: flex;
    gap: 1rem;
  }
`;

const SuccessContainer = styled.div`
  text-align: center;
  font-size: 1.063rem;
`;

const SuccessIcon = styled(NewsletterSuccessIcon)`
  /* stylelint-disable-next-line no-empty-source */
`;

const SuccessText = styled.div`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const SuccessHeadline = styled.div`
  font-weight: bold;
  font-size: 1.7rem;
  padding: 1.5rem;
`;

const Headline = styled.div`
  margin: 0 0 1rem;
  font-weight: bold;
  font-size: 1rem;
`;

const LegalText = styled.div<{ showAllText: boolean; isIndexType: boolean }>`
  margin: ${({ isIndexType }) => (isIndexType ? '1rem 0 0' : '1rem 0')};
  color: ${({ theme }) => theme.color.grey01};
  font-size: 0.875rem;
  line-height: 1.5;
  position: relative;

  ${({ showAllText }) =>
    !showAllText &&
    css`
      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        content: '';
        background: linear-gradient(
          to top,
          rgb(255 255 255 / 100%) 1%,
          rgb(255 255 255 / 0%) 50%
        );
        pointer-events: none;
      }
    `};

  a {
    color: ${({ theme }) => theme.color.brand};
    text-decoration: none;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.color.text};
    }

    &:focus-visible {
      border-radius: 0.5rem;
      outline: 0.125rem dashed ${({ theme }) => theme.color.secondary};
      outline-offset: 0.25rem;
    }
  }
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const NewsletterPictogramWrapper = styled.div`
  margin-bottom: -0.9rem;
  margin-left: 1.5rem;
`;

const NewsletterContainer = styled.article`
  position: relative;
  display: grid;
  ${({ theme }) => theme.mq('md')} {
    grid-template-columns: repeat(2, 1fr);
  }

  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing[50]};
  margin-bottom: -1rem;
`;

const BadgeWrapper = styled.div`
  position: relative;
  right: -1rem;
  top: -1.5rem;
  ${({ theme }) => theme.mq('md')} {
    right: 1rem;
    top: -3rem;
  }
`;

const NewsletterText = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-family: ${({ theme }) => theme.fontFamily.alternate};
`;

const Title = styled.h2`
  font-weight: bold;
  padding-top: 0.75rem;
  font-size: 2rem;
  line-height: 1.25em;
`;

const List = styled.ul`
  color: ${({ theme }) => theme.color.black};
`;

const ListItem = styled.li`
  font-size: 1.063rem;
  font-weight: 400;
  line-height: 2.588em;
  display: flex;
  align-items: center;
`;

const StyledChecked = styled(Checked)`
  fill: ${({ theme }) => theme.color.black};
  width: 2rem;
  display: inline-flex;
  padding-right: 0.5rem;
`;

const StyledArrowDown = styled(ArrowDown)`
  fill: ${({ theme }) => theme.color.brand};
  width: 1.5rem;
  display: inline-flex;
`;

const StyledArrowUp = styled(ArrowUp)`
  fill: ${({ theme }) => theme.color.brand};
  width: 1.5rem;
  display: inline-flex;
`;

const ToggleLink = styled(Ui.TextLink)`
  margin-bottom: ${({ theme }) => theme.spacing[40]};
  font-size: 1.1rem;
  padding-top: 5px;
  text-decoration: none;
`;

export {
  Container,
  SuccessContainer,
  Form,
  Headline,
  LegalText,
  Card,
  NewsletterPictogramWrapper,
  NewsletterContainer,
  StyledBadge,
  BadgeWrapper,
  NewsletterText,
  Title,
  List,
  ListItem,
  StyledChecked,
  ToggleLink,
  StyledArrowDown,
  StyledArrowUp,
  CardWrapper,
  SuccessText,
  SuccessHeadline,
  SuccessIcon,
};
