import { LandingPage } from '../../../entity';
import {
  PageClassificationStrategy,
  PageClassificationStrategyBase,
} from '../pageClassificationStrategy';
import { DomainKeyClassificationRule } from './rule';

class DomainKeyClassificationStrategy
  extends PageClassificationStrategyBase
  implements PageClassificationStrategy
{
  private domainKeys: string[];

  constructor({
    domainKey,
    includeBreadcrumbs,
    pageType,
    pillars,
    omitStageImage,
  }: DomainKeyClassificationRule) {
    super(pageType, pillars, includeBreadcrumbs, omitStageImage);

    this.domainKeys = Array.isArray(domainKey) ? domainKey : [domainKey];

    this.classify = this.classify.bind(this);
    this.strategyApplies = this.strategyApplies.bind(this);
  }

  classify(_page: LandingPage) {
    return this.withCommonValues({});
  }

  strategyApplies({ slug }: LandingPage): boolean {
    if (!slug || slug.length === 0) {
      return false;
    }

    return !!this.domainKeys.find(
      key => slug.toLocaleLowerCase().indexOf(key.toLocaleLowerCase()) === 0,
    );
  }
}

export { DomainKeyClassificationStrategy };
