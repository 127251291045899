import type { PropsWithChildren } from 'react';
import { Wrapper, Swirl, Text, Description } from './PromotionBanner.styles';
import SwirlImage from './swirl.png';

type PromotionBannerProps = PropsWithChildren<{
  title?: string;
  subtitle?: string;
  background?: 'gradient' | string;
}>;

export const PromotionBanner = ({
  children,
  title,
  subtitle,
  background = 'gradient',
}: PromotionBannerProps) => (
  <Wrapper $background={background}>
    <Text $hasSubtitle={!!subtitle}>{title}</Text>
    {subtitle && <Description>{subtitle}</Description>}
    {children}
    <Swirl src={SwirlImage} width={360} height={192} alt="" />
  </Wrapper>
);
