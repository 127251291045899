import { isAxiosError } from 'axios';
import {
  AUTH_CHANNEL,
  http,
  useBroadcastChannel,
} from '@silvertours/front-shared';
import { useCallback } from 'react';
import { useAccountConfig, useWhitelabel } from '../Runtime';
import { useAccount } from './context';

type SessionData = {
  securityTokenValue: string;
  isMobileUserAgent: boolean;
  gtmEnvironment: 'dev' | 'live';
  anonymizeIP: boolean;
};

enum LoginError {
  Blocked = 'blocked',
  ExpiredPassword = 'expiredPassword',
  Unauthorized = 'unauthorized',
  Unknown = 'unknown',
  UnverifiedEmail = 'unverifiedEmail',
  Validation = 'validation',
  WeakPassword = 'weakPassword',
}

type LoginResponse = {
  access_token: string;
  id_token: string;
  refresh_token: string;
};

type LoginErrorResponse = {
  type: string;
  details?: string[];
};

type LoginData = {
  loggedIn: boolean;
  error?: LoginError;
};

const handleLoginError = (error: string) => {
  switch (error) {
    case '/general/errors/bad-request':
    case '/auth/errors/login/bad-request':
    case '/auth/errors/login/invalid-credentials':
      return LoginError.Validation;
    case '/auth/errors/login/expired-password':
      return LoginError.ExpiredPassword;
    case '/auth/errors/login/unverified-email':
      return LoginError.UnverifiedEmail;
    case '/auth/errors/login/weak-password':
      return LoginError.WeakPassword;
    case '/general/errors/undefined':
    case '/auth/errors/login/undefined':
    default:
      return LoginError.Unknown;
  }
};

const updateAccountSession = async (
  url: string,
  accessToken: string,
  refreshToken: string,
) => {
  const res = await http.axiosInstance.post(url, {
    access_token: accessToken,
    refresh_token: refreshToken,
  });
  return res.status === 204;
};

const resendConfirmUser = async (
  url: string,
  emailAddress: string,
  whitelabel: string,
) => {
  try {
    const res = await http.axiosInstance.post(
      url,
      {
        email_address: emailAddress,
      },
      {
        headers: {
          'X-Whitelabel': whitelabel,
        },
      },
    );

    return res.status === 204;
  } catch (error) {
    return false;
  }
};

const useAccountRequest = () => {
  const channel = useBroadcastChannel(AUTH_CHANNEL);
  const {
    accessToken,
    refreshToken,
    setAccessToken,
    setRefreshToken,
    clearAccountState,
  } = useAccount();

  const { whitelabel } = useWhitelabel();
  const { formatApiUrl, formatSessionUrl } = useAccountConfig();

  const login = useCallback(
    async (username: string, password: string) => {
      const url = formatApiUrl(http.API.accountLogin);
      const urlSession = formatSessionUrl(http.API.accountSession);

      try {
        const res = await http.axiosInstance.post<
          LoginResponse | LoginErrorResponse
        >(
          url,
          {
            username,
            password,
          },
          {
            headers: {
              'X-Whitelabel': whitelabel,
            },
          },
        );

        if (res.status >= 200 && res.status <= 299) {
          const data = res.data as LoginResponse;
          setAccessToken(data.access_token);
          setRefreshToken(data.refresh_token);

          await updateAccountSession(
            urlSession,
            data.access_token,
            data.refresh_token,
          );
        }

        return {
          loggedIn: true,
        } as LoginData;
      } catch (error) {
        if (isAxiosError(error)) {
          return {
            loggedIn: false,
            error: handleLoginError(error.response?.data.type),
          } as LoginData;
        }

        return {
          loggedIn: false,
          error: LoginError.Unknown,
        } as LoginData;
      }
    },
    [
      formatApiUrl,
      formatSessionUrl,
      setAccessToken,
      setRefreshToken,
      whitelabel,
    ],
  );

  const logout = useCallback(() => {
    const url = formatApiUrl(http.API.accountLogout);

    try {
      clearAccountState();

      http.axiosInstance.post(
        url,
        {
          refresh_token: refreshToken,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'X-Whitelabel': whitelabel,
          },
        },
      );
      channel.sendMessage({ type: 'logout' });

      return true;
    } catch (error) {
      return false;
    }
  }, [
    formatApiUrl,
    refreshToken,
    accessToken,
    whitelabel,
    clearAccountState,
    channel,
  ]);

  return { login, logout };
};

export { resendConfirmUser, LoginError, useAccountRequest };
export type { SessionData };
