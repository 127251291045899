import { account } from '@silvertours/front-entities';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export type LoginProps = {
  error: account.LoginError | null;
  isLoggedIn: boolean;
  setError: Dispatch<SetStateAction<account.LoginError | null>>;
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
  setShowLogin: (show: boolean) => void;
  setShowMobileLogin: (show: boolean) => void;
  setUsername: Dispatch<SetStateAction<string>>;
  showLogin: boolean;
  showMobileLogin: boolean;
  username: string;
};

const LoginContext = createContext<LoginProps | null>(null);

const useLogin = () => {
  const context = useContext(LoginContext);

  if (context === null) {
    throw new Error(
      'Ensure you have wrapped the login form in a LoginProvider',
    );
  }

  return context;
};

export { LoginContext, useLogin };
