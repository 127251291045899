import React from 'react';

import { LayoutLegacy } from '@silvertours/front-legacy-entities';

import { Button } from '@silvertours/front-shared';
import { UiLegacy } from '@silvertours/front-legacy-shared';
import { useTranslations } from 'next-intl';
import { useSiteInfo } from '@silvertours/front-entities';
import {
  ContactText,
  PhoneNumberContainer,
  PhoneNumberIcon,
} from './SupportInfo.styles';

const ContactUs = () => {
  const t = useTranslations('features.supportInfoLegacy');
  const { featureToggles } = useSiteInfo();

  if (featureToggles.hideInfoline) {
    return null;
  }

  const handleClick = () => {
    window.location.assign(`tel:${t('phoneNumber.link')}`);
  };

  return (
    <LayoutLegacy.PlainSegment id="contact-us">
      <PhoneNumberContainer>
        <ContactText>
          <UiLegacy.PlainText>
            <b>{t('contactUs.title')}</b>
          </UiLegacy.PlainText>
          <UiLegacy.PlainText>{t('contactUs.text')}</UiLegacy.PlainText>
        </ContactText>
        <Button
          variant="secondary"
          icon={<PhoneNumberIcon />}
          onClick={handleClick}
        >
          {t('phoneNumber.display')}
        </Button>
      </PhoneNumberContainer>
    </LayoutLegacy.PlainSegment>
  );
};

export { ContactUs };
