import { RentalLocation } from '@silvertours/front-entities';
import { LanguageCode } from '@silvertours/common-landingpages-view';
import { SearchHistoryItem, SearchHistoryType } from '../SearchHistory/types';

export enum ActionType {
  CLEAR_SEARCH_HISTORY = 'CLEAR_SEARCH_HISTORY',
  POPULATE_HISTORY_ITEMS = 'POPULATE_HISTORY_ITEMS',
  SET_CUSTOMER_AGE = 'SET_CUSTOMER_AGE',
  SET_PROVIDER = 'SET_PROVIDER',
  SET_DATE = 'SET_DATE',
  SET_LOCATION_INPUT_VALUE = 'SET_LOCATION_INPUT_VALUE',
  SET_LOCATION = 'SET_LOCATION',
  SET_ERROR = 'SET_ERROR',
  SET_SHOW_DEST_LOCATION = 'SET_SHOW_DEST_LOCATION',
  SET_SEARCH_FROM_HISTORY = 'SET_SEARCH_FROM_HISTORY',
  VALIDATE = 'VALIDATE',
}

export const isFormError = (error: string): error is FormError['kind'] =>
  ['locationError', 'dateError', 'axiosError'].includes(error);

export type FormError = {
  kind: 'locationError' | 'dateError' | 'axiosError';
  message: string;
};

export type RentalDirection = 'departure' | 'destination';

export type SearchContextProps = {
  clearSearchHistory: () => void;
  getHistoryItemsByLanguage: (language: LanguageCode) => SearchHistoryItem[];
  getInputValue: (direction: RentalDirection) => string;
  isInternalCall: boolean;
  makeSetLocation: (
    direction: RentalDirection,
  ) => (location: RentalLocation | null) => void;
  prefilterQuery?: string;
  setCustomerAge: (customerAge: number) => void;
  setProvider: (provider: string) => void;
  setDate: (direction: RentalDirection, date: Date) => void;
  setInputValue: (direction: RentalDirection, value: string) => void;
  setSearchFromHistory: (item: Partial<SearchFormState>) => void;
  setShowDestLocation: (show: boolean) => void;
  setError: (error: FormError | null) => void;
  state: SearchFormState | null;
  validate: () => void;
};

export type SearchFormState = {
  affiliate?: string;
  customerAge: number;
  provider?: string;
  dateError?: string;
  depDate: string;
  depLocation: RentalLocation | null;
  destDate: string;
  destLocation: RentalLocation | null;
  history: SearchHistoryType;
  imageUrl?: string;
  inputValue: { [Key in RentalDirection]: string };
  locationError?: string;
  showDestLocation: boolean;
  axiosError?: string;
};

export type ActionWithPayload<T extends ActionType, P> = {
  type: T;
  payload: P;
};

type DatePayload = { date: Date; direction: RentalDirection };
type LocationPayload = {
  direction: RentalDirection;
  location: RentalLocation | null;
};
type LocationInputPayload = { direction: RentalDirection; value: string };
type AgePayload = { customerAge: number };
type ProviderPayload = { provider: string };
type ErrorPayload = FormError | null;
type HistoryPayload = { history: SearchHistoryType };
type HistorySelectionPayload = Partial<SearchFormState>;

export type Action =
  | ActionWithPayload<ActionType.POPULATE_HISTORY_ITEMS, HistoryPayload>
  | ActionWithPayload<ActionType.SET_CUSTOMER_AGE, AgePayload>
  | ActionWithPayload<ActionType.SET_PROVIDER, ProviderPayload>
  | ActionWithPayload<ActionType.SET_DATE, DatePayload>
  | ActionWithPayload<ActionType.SET_LOCATION, LocationPayload>
  | ActionWithPayload<ActionType.SET_LOCATION_INPUT_VALUE, LocationInputPayload>
  | ActionWithPayload<ActionType.SET_ERROR, ErrorPayload>
  | ActionWithPayload<ActionType.SET_SHOW_DEST_LOCATION, boolean>
  | ActionWithPayload<
      ActionType.SET_SEARCH_FROM_HISTORY,
      HistorySelectionPayload
    >
  | { type: ActionType.CLEAR_SEARCH_HISTORY }
  | { type: ActionType.VALIDATE };
