import { useEffect, useMemo, useState } from 'react';

export const AUTH_CHANNEL = 'auth_channel';

type Message = {
  type: 'logout';
};

// Send messages across browser tabs
export const useBroadcastChannel = (channelName: string) => {
  const [message, setMessage] = useState<null | Message>(null);
  const channel = useMemo(
    () => new BroadcastChannel(channelName),
    [channelName],
  );

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      setMessage(event.data);
    };

    channel.onmessage = handleMessage;

    // Clean up the channel when the component unmounts
    return () => {
      channel.close();
    };
  }, [channel]);

  const sendMessage = (msg: Message) => {
    channel.postMessage(msg);
  };

  return { message, sendMessage };
};
