import React, { Reducer, useCallback, useEffect, useMemo } from 'react';
import { useTranslations } from 'next-intl';
import {
  LanguageCode,
  SearchData,
} from '@silvertours/common-landingpages-view';
import {
  Runtime,
  useSiteInfo,
  type RentalLocation,
} from '@silvertours/front-entities';
import { useSearchInitializer } from './useInitializeSearchContext';
import {
  Action,
  ActionType,
  FormError,
  RentalDirection,
  SearchContextProps,
  SearchFormState,
} from './types';
import { getSearchHistory } from '../SearchHistory/utils';
import {
  getDefaultValuesPerLanguage,
  makeTranslatedSearchReducer,
} from './reducer';

export const SearchContext = React.createContext<SearchContextProps>({
  clearSearchHistory: () => null,
  getInputValue: () => '',
  getHistoryItemsByLanguage: () => [],
  isInternalCall: false,
  setCustomerAge: () => null,
  setProvider: () => null,
  setDate: () => null,
  setInputValue: () => null,
  makeSetLocation: () => () => null,
  setError: () => null,
  setSearchFromHistory: () => null,
  setShowDestLocation: () => null,
  state: null,
  validate: () => null,
});

export type SearchProviderProps = React.PropsWithChildren<{
  pageData?: SearchData;
}>;

const SearchProvider = ({ children, pageData }: SearchProviderProps) => {
  const t = useTranslations('features.stageLegacy.stage.searchForm');

  const searchReducer = makeTranslatedSearchReducer(t);
  const { initializeFormState } = useSearchInitializer();
  const {
    settings: { isInternalCall },
  } = useSiteInfo();
  const { language } = Runtime.useLocale();

  const [state, dispatch] = React.useReducer<
    Reducer<SearchFormState, Action>,
    SearchFormState
  >(searchReducer, getDefaultValuesPerLanguage(language), defaultValues =>
    initializeFormState(defaultValues, pageData?.location || null),
  );

  const validate = () => {
    dispatch({ type: ActionType.VALIDATE });
  };

  const setCustomerAge = (customerAge: number) => {
    dispatch({
      type: ActionType.SET_CUSTOMER_AGE,
      payload: { customerAge },
    });
  };

  const setProvider = (provider: string) => {
    dispatch({
      type: ActionType.SET_PROVIDER,
      payload: { provider },
    });
  };

  const setDate = (direction: RentalDirection, date: Date) => {
    dispatch({
      type: ActionType.SET_DATE,
      payload: { direction, date },
    });
  };

  const makeSetLocation =
    (direction: RentalDirection) => (_location: RentalLocation | null) => {
      dispatch({
        type: ActionType.SET_LOCATION,
        payload: { direction, location: _location },
      });
    };

  const setError = (error: FormError | null) => {
    dispatch({
      type: ActionType.SET_ERROR,
      payload: error,
    });
  };

  const setShowDestLocation = (show: boolean) => {
    dispatch({ type: ActionType.SET_SHOW_DEST_LOCATION, payload: show });
  };

  const setSearchFromHistory = (payload: Partial<SearchFormState>) => {
    dispatch({ type: ActionType.SET_SEARCH_FROM_HISTORY, payload });
  };

  const clearSearchHistory = () => {
    dispatch({ type: ActionType.CLEAR_SEARCH_HISTORY });
  };

  const getHistoryItemsByLanguage = useCallback(
    (languageCode: LanguageCode) =>
      state.history.items.filter(item => item.language === languageCode),
    [state.history.items],
  );

  const setInputValue = (direction: RentalDirection, value: string) => {
    dispatch({
      type: ActionType.SET_LOCATION_INPUT_VALUE,
      payload: { direction, value },
    });
  };

  useEffect(() => {
    const history = getSearchHistory();

    if (history) {
      dispatch({
        type: ActionType.POPULATE_HISTORY_ITEMS,
        payload: { history },
      });
    }
  }, [pageData?.location]);

  const value = useMemo(
    () => ({
      clearSearchHistory,
      getInputValue: (direction: RentalDirection) =>
        state.inputValue[direction],
      getHistoryItemsByLanguage,
      isInternalCall,
      setCustomerAge,
      setProvider,
      setDate,
      setError,
      state,
      setInputValue,
      makeSetLocation,
      setSearchFromHistory,
      setShowDestLocation,
      validate,
      prefilterQuery: pageData?.prefilterQuery,
    }),
    [
      getHistoryItemsByLanguage,
      isInternalCall,
      state,
      pageData?.prefilterQuery,
    ],
  );

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export { SearchProvider };
