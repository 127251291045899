import { useTranslations } from 'next-intl';
import {
  ContentLayout,
  HomePageContent,
} from '@silvertours/common-landingpages-view';
import {
  BenefitsLegacy,
  ContentArticle,
  NewsletterRegistrationLegacy,
  OffersModule,
  SerpOffersModule,
  StageLegacy,
  TrustLegacy,
} from '@silvertours/front-features';

import { Body } from '../body';

const HomeContent = ({
  content: {
    article,
    globalRating,
    introduction,
    links,
    offersByCategory,
    stage,
    textContent: { primaryHeadline },
  },
  ...commonProps
}: ContentLayout<HomePageContent>) => {
  const t = useTranslations('features');
  const translations = t.raw('article' as any);
  const offersTranslations = useTranslations('features.offers');

  return (
    <Body
      title={primaryHeadline}
      stage={
        <StageLegacy.Stage
          content={stage.content}
          headline={primaryHeadline}
          isHome
        />
      }
      content={({ containerModule }) => [
        containerModule(
          'usp',
          <BenefitsLegacy.Usp rating={globalRating.content} />,
        ),
        containerModule(
          'introduction-content',
          <ContentArticle
            modules={introduction?.segments ?? []}
            translations={translations}
          />,
          !!introduction?.segments,
        ),
        containerModule(
          'serpOffers',
          !!offersByCategory.content && (
            <SerpOffersModule
              content={offersByCategory.content}
              translations={{
                airConditioning: offersTranslations('car.airConditioning'),
                carTypes: t.raw('offers.car.type' as any),
                prevText: offersTranslations('prevText'),
                nextText: offersTranslations('nextText'),
                searchButtonText: offersTranslations('searchButtonText'),
                disclaimertext: offersTranslations('disclaimerSerpOffers'),
                pricePrefix: offersTranslations('pricePrefix'),
                priceSuffix: offersTranslations('priceSuffix'),
                serpTitle: offersTranslations('serp.title'),
                transmissionAutomatic: offersTranslations(
                  'car.transmissionAutomatic',
                ),
                transmissionManual: offersTranslations(
                  'car.transmissionManual',
                ),
              }}
            />
          ),
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        containerModule(
          'offers',
          !!offersByCategory.content && (
            <OffersModule
              content={offersByCategory.content}
              translations={{
                badgeText: offersTranslations('badge'),
                disclaimer: offersTranslations('disclaimer'),
                disclaimerOverlayCTA: offersTranslations(
                  'disclaimerOverlayCTA',
                ),
                durationFrom: offersTranslations('durationFrom'),
                headline: offersTranslations('headline'),
                pricePrefix: offersTranslations('pricePrefix'),
                priceSuffix: offersTranslations('priceSuffix'),
                searchButtonText: offersTranslations('searchButtonText'),
                offerDetailsTitle: offersTranslations('offerDetailsTitle'),
                carTypes: t.raw('offers.car.type' as any),
              }}
            />
          ),
          !!offersByCategory.content &&
            Object.keys(offersByCategory.content).length > 0,
        ),
        containerModule('test-winner', <TrustLegacy.CarigamiGarantie />),
        containerModule(
          'article',
          <ContentArticle
            modules={article.segments}
            translations={translations}
          />,
        ),
        containerModule(
          'newsletter',
          <NewsletterRegistrationLegacy.NewsletterRegistration type="index" />,
        ),
        containerModule(
          'linkLists',
          <ContentArticle
            modules={links.content ?? []}
            translations={translations}
          />,
          !!links.content.length,
        ),
      ]}
      {...commonProps}
    />
  );
};

export { HomeContent };
