import { Reducer } from 'react';
import { setSeconds } from 'date-fns/setSeconds';
import { setMinutes } from 'date-fns/setMinutes';
import { setHours } from 'date-fns/setHours';
import { addDays } from 'date-fns/addDays';
import type { LanguageCode } from '@silvertours/common-landingpages-view';
import {
  isDestDateBeforeDepDate,
  formatDate,
  isDeptDateInLessThanDays,
  isSearchInCuba,
} from './utils';
import { Action, ActionType, RentalDirection, SearchFormState } from './types';

const LOCATION_FIELD_NAME: {
  [Key in RentalDirection]: 'depLocation' | 'destLocation';
} = {
  departure: 'depLocation',
  destination: 'destLocation',
};

export const DEFAULT_AGE = 47;

const today = new Date();

export const getDefaultDepartureDatePerLanguage = (language: LanguageCode) => {
  const hours = language === 'fr' ? 11 : 12;
  return setSeconds(setMinutes(setHours(addDays(today, 7), hours), 0), 0);
};

export const getDefaultDestinationDate = () =>
  setSeconds(setMinutes(setHours(addDays(today, 14), 9), 0), 0);

export const getDefaultValuesPerLanguage = (language: LanguageCode) => ({
  affiliate: '',
  customerAge: DEFAULT_AGE,
  provider: '',
  depLocation: null,
  destLocation: null,
  depDate: formatDate(getDefaultDepartureDatePerLanguage(language)),
  destDate: formatDate(getDefaultDestinationDate()),
  dateError: '',
  locationError: '',
  axiosError: '',
  showDestLocation: false,
  history: { items: [] },
  inputValue: { departure: '', destination: '' },
});

export const makeTranslatedSearchReducer =
  (translate: (key: any) => string): Reducer<SearchFormState, Action> =>
  (state, action) => {
    switch (action.type) {
      case ActionType.CLEAR_SEARCH_HISTORY:
        return { ...state, history: { items: [] } };

      case ActionType.POPULATE_HISTORY_ITEMS:
        return { ...state, history: action.payload.history };

      case ActionType.SET_CUSTOMER_AGE:
        return {
          ...state,
          customerAge: action.payload.customerAge,
        };

      case ActionType.SET_PROVIDER:
        return {
          ...state,
          provider: action.payload.provider,
        };

      case ActionType.SET_DATE: {
        const date = formatDate(action.payload.date);
        const field =
          action.payload.direction === 'departure' ? 'depDate' : 'destDate';

        const hasError =
          action.payload.direction === 'departure'
            ? isDestDateBeforeDepDate(state.destDate, date)
            : isDestDateBeforeDepDate(date, state.depDate);

        return {
          ...state,
          [field]: date,
          dateError: hasError ? translate('errorTexts.time') : '',
          locationError: '',
          axiosError: '',
        };
      }

      case ActionType.SET_LOCATION: {
        const { direction, location } = action.payload;
        const field = LOCATION_FIELD_NAME[direction];

        return {
          ...state,
          [field]: location,
          inputValue: {
            ...state.inputValue,
            ...(location ? { [direction]: location.name } : {}),
          },
        };
      }

      case ActionType.SET_LOCATION_INPUT_VALUE: {
        const { direction, value } = action.payload;
        return {
          ...state,
          inputValue: { ...state.inputValue, [direction]: value },
        };
      }

      case ActionType.SET_ERROR: {
        if (action.payload === null) {
          return {
            ...state,
            dateError: '',
            locationError: '',
            axiosError: '',
          };
        }
        const { kind, message } = action.payload;
        return { ...state, [kind]: message };
      }

      case ActionType.SET_SEARCH_FROM_HISTORY: {
        const { depLocation, destLocation } = action.payload;
        return {
          ...state,
          ...action.payload,
          showDestLocation: Boolean(destLocation),
          inputValue: {
            ...state.inputValue,
            ...(depLocation ? { departure: depLocation.name } : {}),
            destination: destLocation?.name || '',
          },
        };
      }

      case ActionType.SET_SHOW_DEST_LOCATION:
        return {
          ...state,
          showDestLocation: action.payload,
          ...(action.payload
            ? {}
            : {
                destLocation: null,
                inputValue: { ...state.inputValue, destination: '' },
              }),
        };

      case ActionType.VALIDATE:
        if (isDeptDateInLessThanDays(state.depDate, 0)) {
          return {
            ...state,
            dateError: translate('errorTexts.time'),
          };
        }
        if (isDestDateBeforeDepDate(state.destDate, state.depDate)) {
          return { ...state, dateError: translate('errorTexts.time') };
        }
        if (
          isSearchInCuba(state.depLocation, state.destLocation) &&
          isDeptDateInLessThanDays(state.depDate, 30)
        ) {
          return { ...state, locationError: translate('errorTexts.cuba') };
        }
        return state;

      default:
        return state;
    }
  };
