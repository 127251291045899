import { account, analytics } from '@silvertours/front-entities';
import React from 'react';
import { useTranslations } from 'next-intl';
import { useLogin } from '../../LoginForm';
import { List, NavigationItem, StyledLink } from './LoggedInActions.styles';

const LoggedInActions = () => {
  const { isLoggedIn } = useLogin();
  const { logout } = account.useAccountRequest();
  const menuTranslations = useTranslations(
    'features.navigationLegacy.navigation.login.button.authenticated.menu',
  );
  const t = useTranslations('features.navigationLegacy.navigation');

  const accountLinks = [
    {
      text: menuTranslations('myBookings.text'),
      href: menuTranslations('myBookings.href'),
      onClick: () => {
        analytics.gtm.trackMyBookings({ isLoggedIn });
      },
    },
    {
      text: menuTranslations('myData.text'),
      href: menuTranslations('myData.href'),
      onClick: () => {
        analytics.gtm.trackMyData({ isLoggedIn });
      },
    },
    {
      text: menuTranslations('myPayment.text'),
      href: menuTranslations('myPayment.href'),
      onClick: () => {
        analytics.gtm.trackMyPayment({ isLoggedIn });
      },
    },
    {
      text: t('logout.text'),
      href: t('logout.href'),
      onClick: () => {
        logout();
        analytics.gtm.trackLogout();
      },
    },
  ];

  return (
    <List role="menu" aria-label={t('login.button.account')}>
      {accountLinks.map((link, index) => (
        <React.Fragment key={`nav-${link.href}`}>
          {index === accountLinks.length - 1 && <hr />}
          <NavigationItem role="presentation">
            <StyledLink
              text={link.text}
              href={link.href}
              role="menuitem"
              onClick={link.onClick}
            />
          </NavigationItem>
        </React.Fragment>
      ))}
    </List>
  );
};

export { LoggedInActions };
