import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

const baseConfig: AxiosRequestConfig = {
  timeout: 8000,
  withCredentials: true,
  headers: {
    'client-id': 'web',
  },
};

const axiosInstance = axios.create(baseConfig);

const getApi = (
  host: string,
  config?: AxiosRequestConfig,
  version: string = 'v1',
  protocol?: string,
) => {
  const baseURL = `${protocol || 'https'}://${host}/${version}`;

  return axios.create({
    baseURL,
    ...baseConfig,
    ...config,
    headers: {
      ...baseConfig.headers,
      ...config?.headers,
    },
  });
};

enum API {
  accountLogin = '/auth/v1/login',
  accountLogout = '/auth/v1/logout',
  accountResendConfirmUser = '/user-profile/v1/resend-confirm-user',
  accountSession = '/user_account/session.php',
  openingHours = '/infoline/times',
  siteSettings = '/site/settings',
  siteFeatures = '/site/features',
  searchCars = '/quotes',
  customerReviews = '/customer_reviews',
}

export { axiosInstance, AxiosError, getApi, API };
export type { AxiosResponse };
