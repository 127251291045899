import {
  Runtime,
  useContentModule,
  useSiteInfo,
} from '@silvertours/front-entities';
import { Theme } from '@silvertours/front-shared';
import { useMedia } from 'react-use';
import { AppPromotion } from './TrustElements/AppPromotion';
import { TrustElement, TrustWrapper } from './TrustSeals.styles';
import { Trustpilot } from './TrustElements/Trustpilot';
import { BestPriceGuarantee } from './TrustElements/BestPriceGuarantee';

type TrustSealsProps = {
  includeSearch?: boolean;
  isPlainTemplate?: boolean;
};

const TrustSeals = ({
  includeSearch = true,
  isPlainTemplate = false,
}: TrustSealsProps) => {
  const { featureToggles } = useSiteInfo();
  const { language } = Runtime.useLocale();
  const isTabletViewport = useMedia(
    `(min-width: ${Theme.getBreakpoint('sm')}px)`,
    false,
  );
  const isLargeViewport = useMedia('(min-width: 992px)', false);
  const { getTrustpilotData } = useContentModule();

  const anyFeatureToggleEnabled =
    featureToggles.trustElementOnStageImage ||
    featureToggles.appPromotionOnStageImage ||
    featureToggles.bestPriceGuaranteeOnStageImage;

  if (
    !anyFeatureToggleEnabled ||
    (!isPlainTemplate && !isLargeViewport) ||
    (isPlainTemplate && includeSearch && !isLargeViewport) ||
    !isTabletViewport
  ) {
    return null;
  }

  const trustpilotProps = getTrustpilotData();

  if (language === 'de' || language === 'fr') {
    return (
      <TrustWrapper>
        {featureToggles.appPromotionOnStageImage && (
          <TrustElement isHighlighted>
            <AppPromotion />
          </TrustElement>
        )}
        {featureToggles.trustElementOnStageImage && trustpilotProps && (
          <Trustpilot {...trustpilotProps} />
        )}
        {featureToggles.bestPriceGuaranteeOnStageImage && (
          <BestPriceGuarantee />
        )}
      </TrustWrapper>
    );
  }

  return null;
};

export { TrustSeals };
